import React from 'react';
import { Document, Page, Text, View, Link } from '@react-pdf/renderer';

import { styles } from "../PDFComponentsInvoice/PDFComponentStyle";
import PDFPointHead from "../PDFComponentsInvoice/PDFPointHead";
import PDFPoint from "../PDFComponentsInvoice/PDFPoint";
import PDFPointCommission from "./PDFPointCommission";
import PDFPointSum from "../PDFComponentsInvoice/PDFPointSum";
import PDFHeader from "../PDFComponentsInvoice/PDFHeader";
import PDFFooter from "../PDFComponentsInvoice/PDFFooter";


function PDFComponent({ data, page = "1", activeSing = false }) {

  let price = ''
  let commission = ''
  // 1 Создать простой счет 
  if (page === "1") {
    price = data.price
  }
  // 2 Создать счет на пополнения контекста
  if (page === "2") {
    price = data.price.map(el => el - (el / 100 * 20))
    commission = data.price.map(el => (el / 100 * 20)).reduce((akum, el) => +akum + el)
  }
  // 3 Создать счет на пополнения instagram
  if (page === "3") {
    price = data.price
    commission = data.price.map(el => (el / 100 * 20)).reduce((akum, el) => +akum + el)
  }
  // 4 Создать счет на оплату RBot, RQuiz
  if (page === "4") {
    price = data.price
    commission = data.price.map(el => (el / 100 * 20)).reduce((akum, el) => +akum + el)
  }
  //5. Хостинг
  if (page === "5") {
    price = data.price
    commission = data.price.map(el => (el / 100 * 20)).reduce((akum, el) => +akum + el)
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>

          <PDFHeader firma={data.firma} client={data.client} clientNumber={data.marker ? data.marker + data.clientNumber : data.clientNumber} от date={data.date} email={data.email} name={data.name} />
          {page === "2" || page === "3" ?
            <>
              <Text style={styles.textBold}>Внимание! В назначении платежа, необходимо указать данные в следующем формате:</Text>
              <Text style={[styles.textBold, styles.mb]}>Оплата по счету P{data.clientNumber} от {data.date} г. </Text>
              <Text style={[styles.textBold, styles.mb]}>Если данные не будут указаны, поступление денег на счет может занять до 3-х рабочих дней</Text>
            </>
            : <></>}
          {page === "4" ?
            <>
              <Text style={styles.textBold}>Внимание! В назначении платежа, необходимо указать данные в следующем формате:</Text>
              <Text style={[styles.textBold, styles.mb]}>Оплата по счету B{data.clientNumber} от {data.date} г. </Text>
              <Text style={[styles.textBold, styles.mb]}>Если данные не будут указаны, поступление денег на счет может занять до 3-х рабочих дней</Text>
            </>
            : <></>}
          <PDFPointHead />

          {data.appoint.map((el, i) =>
            <PDFPoint appoint={el} price={price[i]} key={i} />
          )}

          {page === "2" || page === "3" ? <PDFPointCommission commission={commission} page={page} /> : <></>}
          {page === "2" || page === "3" || page === "4" ? <PDFPointSum price={data.price} commission={commission} page={page} /> : <PDFPointSum price={data.price} page={page} firma={data.firma} />}

          {data.cod && <Text style={[styles.textBold, styles.mt]}>Код платежа: {data.cod}</Text>}

          <PDFFooter stamp={data.sing} />

          {page === "2" || page === "3" ? <Link style={styles.link} src="https://mediaritm.by/pdf/dogovor-reclama%20.pdf">Оплатив счет, вы соглашаетесь с условиями публичного договора, размещенного на сайте Исполнителя https://mediaritm.by/pdf/dogovor-reclama%20.pdf</Link> : <></>}
          {page === "4" ? <Link style={styles.link} src="https://rquiz.mediarama.by/dogovor.pdf">Оплатив счет, вы соглашаетесь с условиями публичного договора, размещенного на сайте Исполнителя https://rquiz.mediarama.by</Link> : <></>}
          {page === "1" ?
            <>
              {activeSing && <Link style={styles.link} src="https://mediaritm.by/pdf/dogovor-reclama%20.pdf">Оплатив счет, вы соглашаетесь с условиями публичного договора, размещенного на сайте Исполнителя https://mediaritm.by/pdf/dogovor-reclama%20.pdf</Link>}
            </>
            : <></>}
          {page === "5" || data.page === "5" ?
            <>
              {<Link style={styles.link} src="https://mediarama.by/dogovor.pdf">Оплатив счет, вы соглашаетесь с условиями публичного договора, размещенного на сайте Исполнителя https://mediarama.by</Link>}
            </>
            : <></>}
        </View>
      </Page>
    </Document >
  )
}
export default PDFComponent